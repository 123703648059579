import { message } from 'ant-design-vue';
import mogicWarning from '@/components/icons/mogicWarning.vue';
import mogicErrorCircle from '@/components/icons/mogicErrorCircle.vue';

// const bgcolorMap = {
//   success: '#10B981',
//   error: '#F43F5E',
//   warning: '#FDE047',
//   info: '#F1F5F9',
// }
const iconMap = {
  success: () => h(mogicWarning),
  error: () => h(mogicErrorCircle),
  warning: () => h(mogicWarning),
  info: () => h(mogicWarning),
}

export default function (type, content) {
  message[type]({
    content: content,
    icon: () => h(iconMap[type]),
    class: 'grados-message-notice-' + type,
  })
}